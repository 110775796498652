<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet />
      <ion-tab-bar
        v-if="Capacitor.isNativePlatform()"
        slot="bottom"
      >
        <ion-tab-button
          tab="index"
          :href="homeTabPath"
        >
          <ion-icon
            :icon="ioniconsHomeOutline"
          />
          <ion-label>
            Domov
          </ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="events"
          :href="eventsTabPath"
        >
          <ion-icon
            :icon="ioniconsSparklesOutline"
          />
          <ion-label>
            Podujatia
          </ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="tickets"
          :href="ticketsTabPath"
        >
          <ion-icon
            :icon="ioniconsTicketOutline"
          />
          <ion-label>
            Vstupenky
          </ion-label>
        </ion-tab-button>

        <ion-tab-button
          v-if="authStore.isSignedIn"
          tab="profile"
          :href="profileTabPath"
        >
          <ion-icon
            :icon="ioniconsPersonOutline"
          />
          <ion-label>
            Profil
          </ion-label>
        </ion-tab-button>
        <ion-tab-button
          v-else
          tab="auth"
          :href="authTabPath"
        >
          <ion-icon
            :icon="ioniconsLogInOutline"
          />
          <ion-label>
            Auth
          </ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { Capacitor } from '@capacitor/core'

const localePath = useLocalePath()
const getRouteBaseName = useRouteBaseName()

const authStore = useAuthStore()

const route = useRoute()

const homeTabPath = computed(() => localePath('index'))
const eventsTabPath = computed(() => localePath('index-events'))
const ticketsTabPath = computed(() => localePath('index-tickets'))
const profileTabPath = computed(() => localePath('index-profile'))
const authTabPath = computed(() => getRouteBaseName(route)?.endsWith('signup') ? localePath('index-auth-signup') : localePath('index-auth-signin'))
</script>

<style scoped>
ion-tab-bar {
  border-top: 1px solid var(--ion-toolbar-border-color, var(--ion-border-color, var(--ion-color-step-150, var(--ion-background-color-step-150, rgba(0, 0, 0, 0.1)))));
  --border: 1px solid var(--ion-toolbar-border-color, var(--ion-border-color, var(--ion-color-step-150, var(--ion-background-color-step-150, rgba(0, 0, 0, 0.1)))));
  --background: white;
  padding-right: var(--ion-safe-area-right);
  padding-bottom: var(--ion-safe-area-bottom, 0);
  padding-left: var(--ion-safe-area-left);
}
* {
  border-width: unset;
  border-right: none;
  border-left: none;
  border-bottom: none;
  border-top: unset;
}
</style>
